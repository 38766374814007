<template>
  <main-wrapper>
    <template slot="tool">
      <span class="title ipx-1">风控评估</span>
    </template>
    <template>
      <vcl-code class="ima-4" :width="400" :height="150"></vcl-code>
    </template>
  </main-wrapper>
</template>

<script>
import { VclCode } from 'vue-content-loading'
export default {
  components: {
    VclCode
  }
}
</script>

<style lang="scss" scoped>

</style>
